export default [
  {
    key: 'channelId',
    sortable: false,
    label: 'field.channel',
    thClass: 'w-50px',
  },
  {
    key: 'liveNo',
    sortable: false,
    sortField: '',
    label: 'Live',
    noTranslateLabel: true,
  },
  {
    key: 'no',
    sortable: true,
    sortField: 'fight_match_id',
    label: 'field.fight',
    thClass: 'w-50px',
  },
  {
    key: 'createdAt',
    sortable: true,
    sortField: 'createdAt',
    label: 'field.date',
  },
  {
    key: 'amount',
    label: 'field.betAmount',
  },
  {
    key: 'payout',
    label: 'field.odd',
  },
  {
    key: 'selectedBet',
    label: 'field.bet',
  },
  {
    key: 'matchResult',
    label: 'field.result',
  },
  {
    key: 'winAmount',
    label: 'field.winLoss',
  },
  {
    key: 'commission',
    label: 'field.commission',
  },
  {
    key: 'newWl',
    label: 'field.newWinLoss',
  },
  {
    key: 'paybackAmount',
    label: 'field.payback',
  },
];
