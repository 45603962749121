export default [
  {
    key: 'increasement',
    sortable: false,
    sortField: '',
    label: '#',
    thClass: 'w-50px',
  },
  {
    key: 'name',
    sortable: true,
    sortField: 'name',
    label: 'field.name',
    stickyColumn: true,
    variant: 'light',
  },
  {
    key: 'username',
    sortable: true,
    sortField: 'username',
    label: 'field.username',
  },
  {
    key: 'amount',
    sortable: false,
    sortField: '',
    label: 'field.amount',
    tdClass: 'text-right',
  },
  {
    key: 'promoAmount',
    sortable: false,
    sortField: '',
    label: 'field.promotionAmount',
    tdClass: 'text-right',
  },
  {
    key: 'createdAt',
    sortable: true,
    sortField: 'createdAt',
    label: 'field.createDate',
  },
  {
    key: 'createdBy',
    sortable: true,
    sortField: 'createdBy',
    label: 'field.createdBy',
  },
]
